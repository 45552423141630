import { Dark, Notify, QNotifyCreateOptions } from 'quasar'
import { defineStore } from 'pinia'
import { Composer } from 'vue-i18n'

export const useUiStore = defineStore('ui', {
	state: () => ({
		$i18n: {} as any,
		locale: 'ru-RU',
		darkMode: false,
		isLeftDrawerOpen: false,
		isCookiesAccepted: false,
	}),

	getters: {
		i18n(): Composer {
			return this.$i18n.global
		},
	},
	actions: {
		setDarkMode(setDark: boolean) {
			Dark.set(setDark)
			this.darkMode = Dark.isActive
		},

		toggleDarkMode() {
			Dark.toggle()
			this.darkMode = Dark.isActive
		},

		showError(e: any) {
			const message =
				e.response?.data?.error?.message ||
				e.response?.message ||
				e.message ||
				e.toString() ||
				e
			// const message = this.i18n.t('errors.unknownError')
			Notify.create({
				color: 'red-5',
				textColor: 'white',
				iconColor: 'white',
				icon: 'error',
				closeBtn: 'X',
				message,
			})
			console.error(e)
			return message
		},

		showSuccess(msg?: string) {
			console.log(Notify)
			Notify.create({
				color: 'green-5',
				textColor: 'white',
				icon: 'info',
				message: msg || this.i18n.t('success'),
			})
		},

		showMessage(opts: QNotifyCreateOptions | string) {
			Notify.create(opts)
		},
	},
	persistedState: {
		persist: true,
	},
})
