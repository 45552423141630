import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: () => import('layouts/main-layout.vue'),
		children: [
			{
				path: '',
				component: () => import('pages/index-page.vue'),
			},
			{
				path: 'stores',
				component: () => import('pages/store-pages/stores-list-page.vue'),
				props: { name: 'StoresList' },
			},
			{
				path: 'stores/:id',
				component: () => import('pages/store-pages/one-store-page.vue'),
				props: { name: 'Store' },
			},
			{
				path: 'store/:id/products',
				component: () => import('pages/development/index.vue'),
				props: { name: 'Products of the store' },
			},
			{
				path: 'products',
				component: () => import('pages/qr-page.vue'),
				props: { name: 'Scan QR Code' },
			},
			{
				path: 'product/:id',
				component: () => import('pages/development/index.vue'),
				props: { name: 'ProductItem' },
			},
			{
				path: 'profile/:page?',
				component: () => import('pages/profile-pages/profile-page.vue'),
			},
			{
				path: 'profile/check-history',
				component: () =>
					import('pages/profile-pages/profile-check-history-page.vue'),
			},
			{
				path: 'cart/:page?',
				component: () => import('pages/cart-pages/cart-list-page.vue'),
			},
			{
				path: 'cart/successful-payment',
				component: () =>
					import('pages/cart-pages/cart-successful-payment-page.vue'),
			},
			{
				path: 'cart/checkout-payment/:page?',
				component: () =>
					import('pages/cart-pages/cart-checkout-payment-page.vue'),
			},
		],
	},

	// Always leave this as last one,
	// but you can also remove it
	{
		path: '/:catchAll(.*)*',
		component: () => import('pages/errors/error-not-found-page.vue'),
	},
]

export default routes
