import { IUserEntity } from './entities'

export interface ILoginByEmailInput {
	email: string
	password?: string
}

export interface IRegisterByEmailInput extends ILoginByEmailInput {
	password: string
	group: UserGroupsEnum
}

export interface ITokens {
	accessToken: string
	refreshToken: string
}

export interface IUserWithTokens {
	user: IUserEntity
	tokens: ITokens
}

export enum UserGroupsEnum {
	roots = 'Roots',
	admins = 'Administrators',
	users = 'Users',
	customers = 'Customers',
}

export enum UserStatusEnum {
	active = 'active',
	inactive = 'inactive',
	paused = 'paused',
	blocked = 'blocked',
}

export enum NotificationStatusEnum {
	new = 'new',
	sent = 'sent',
	seen = 'seen',
}

export enum ProductTypeEnum {
	product = 'product',
	modifier = 'modifier',
	material = 'material',
}
