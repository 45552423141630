export default {
	locale: 'Русский',
	failed: 'Не получилось',
	success: 'Получилось!',
	appTitle: 'ASSA',
	cartPageTitle: 'Корзина',
	profilePageTitle: 'Профиль',
	more: 'Подробнее',
	stopScanning: 'Завершить сканирование',
	clickToScan: 'Сканировать',
	question: 'Задать вопрос',
	yourName: 'Ваше имя',
	name: 'Имя',
	phone: 'Телефон',
	orderHistory: 'История заказов',
	save: 'Сохранить',
	common: { back: 'Назад' },
	login: {
		title: 'Привет!',
		byEmail: 'Вход',
		byPhone: 'Телефон',
		appInfo: 'Информация',
		emailLabel: 'email',
		phoneLabel: 'телефон',
		passwordLabel: 'пароль',
		makeCall: 'Сделать звонок',
		sendSMS: 'Выслать SMS',
		codeSmsLabel: 'Код из SMS',
		codeCallLabel: 'Последние 4 цифры номера',
		loginButton: 'Войти',
		registerButton: 'Регистрация',
		backToHome: 'Вернуться на главную',
		activating: 'Активация..',
		useActivateLinkInEmail: 'Отправлен email активации',
		checkEmailButton: 'Далее',
		logout: 'Выйти',
	},
	updater: {
		messagePre: 'Вышла новая версия! Пожалуйста сохраните ваши изменения и',
		messageLink: 'обновите',
		messagePost: 'страницу',
	},
	mainPage: {
		title: 'Добро пожаловать в ASSA!',
		subtitle: 'Всё, что нужно для ведения бизнеса',
		pleaseLogin: 'Пожалуйста, войдите',
	},
	cookies: {
		message:
			'Мы использует файлы cookie: технические, аналитические, маркетинговые и другие. Они необходимы для оптимальной работы сайтов и сервисов, помогают нам узнавать вас и хранить ваши настройки.',
		acceptButton: 'Принять',
	},
	errors: {
		'nothing-here': 'Ой. Тут ничего нет...',
		unknownError: 'Непонятная ошибка',
	},
	menu: {
		Dashboard: 'Дашборд',
		References: 'Справочники',
		Products: 'Продукты',
		'User groups': 'Группы пользователей',
		Users: 'Пользователи',
		Files: 'Файлы',
		Countries: 'Страны',
		'Payment methods': 'Способы оплаты',
		Reports: 'Отчёты',
		Orders: 'Заказы',
		Health: 'Здоровье',
		Support: 'Поддержка',
		About: 'О сервисе',
		Profile: 'Профиль',
		Scan: 'Сканировать',
		Сart: 'Корзина',
		'Trade objects': 'Торговые объекты',
		'Trade areas': 'Залы',
		Table: 'Стол',
		Tables: 'Столы',
		Currency: 'Валюта',
		Feedback: 'Отзывы',
		Notifications: 'Уведомления',
		'Work zones': 'Рабочие зоны',
		Stores: 'Магазины',
	},
	cartPage: {
		total: 'итого',
		listOfPurchase: 'Саписок покукоп',
		methodOfPayment: 'Способ оплаты',
		byCash: 'Наличными',
		receipt: 'Квитанция',
		receipts: 'Квитанции',
		addBag: 'Добавить покет',
		goToPayment: 'Перейти к оплате',
		addItem: 'Добавить товар',
		payOnCash: 'Оплатить на кассе',
		payByKaspi: 'Оплатить Kaspi',
		payingOnCash: 'Оплата на кассе',
		stopPurchase: 'Завершить покупку',
		thereIsNoItemsToShow: 'Нет позиций для показа',
		showThisNumberToCashierToMakePayment:
			'Покажите этот номер кассиру, что бы совершить оплату',
		yourOrderNumberIs: 'Ваш номер заказа',
	},
	profilePage: {
		enterCorrectInformation: 'Пожалуйста, введите правильные данные!',
		pleaseEnterCorrectNumber: 'Пожалуйста, введите правильный номер!',
		messageOfToSeeYourOrderHistory:
			'Чтобы смотреть историю ваших заказов, нужно зарегистрировать ваше имя и номер телефона',
	},
}
