export default {
	locale: 'Қазақ',
	failed: 'Болмады',
	success: 'Болды!',
	appTitle: 'ASSA',
	cartPageTitle: 'Корзина',
	profilePageTitle: 'Профиль',
	more: 'Подробнее',
	stopScanning: 'Завершить сканирование',
	clickToScan: 'Сканировать',
	question: 'Задать вопрос',
	yourName: 'Ваше имя',
	name: 'Имя',
	phone: 'Телефон',
	orderHistory: 'История заказов',
	save: 'Сохранить',
	common: { back: 'Артқа' },
	login: {
		title: 'Сәлеметсіз бе!',
		byEmail: 'Кіру',
		byPhone: 'Телефон',
		appInfo: 'Ақпарат',
		emailLabel: 'Электрондық пошта',
		phoneLabel: 'Телефон',
		passwordLabel: 'Құпия сөз',
		makeCall: 'Қоңырау шалу үшін',
		sendSMS: 'SMS жіберу',
		codeSmsLabel: 'SMS-тен код',
		codeCallLabel: 'Санның соңғы 4 саны',
		loginButton: 'Кіру үшін',
		registerButton: 'Тіркеу',
		backToHome: 'Басты бетке оралу',
		activating: 'Белсендіру..',
		useActivateLinkInEmail: 'Белсендіру электрондық поштасы жіберілді',
		checkEmailButton: 'Әрі қарай',
		logout: 'Выйти',
	},
	updater: {
		messagePre: 'Жаңа нұсқасы шықты! ',
		messageLink: 'жаңарту',
		messagePost: 'Бет',
	},
	mainPage: {
		title: 'ASSA-ға қош келдіңіз!',
		subtitle: 'Бизнесті жүргізу үшін қажет нәрсенің бәрі',
		pleaseLogin: 'Кіріңіз',
	},
	cookies: {
		message:
			'Біз cookie файлдарын қолданамыз: техникалық, аналитикалық, маркетингтік және т.б. ',
		acceptButton: 'Қабылдау',
	},
	errors: {
		'nothing-here': 'О. Мұнда ештеңе жоқ...',
		unknownError: 'Түсініксіз қате',
	},
	menu: {
		Dashboard: 'Бақылау тақтасы',
		References: 'Анықтамалық әдебиеттер',
		Products: 'Өнімдер',
		'User groups': 'Пайдаланушылар тобы',
		Users: 'Пайдаланушылар',
		Files: 'Файлдар',
		Countries: 'Елдер',
		'Payment methods': 'Төлем әдістері',
		Reports: 'Есептер',
		Orders: 'Тапсырыстар',
		Health: 'Денсаулық',
		Support: 'Қолдау',
		About: 'Қызмет туралы',
		Profile: 'Профиль',
		Сart: 'Корзина',
		Scan: 'Сканировать',
		'Trade objects': 'Сауда объектілері',
		'Trade areas': 'Залдар',
		Table: 'Кесте',
		Tables: 'Кестелер',
		Currency: 'Валюта',
		Feedback: 'Пікірлер',
		Notifications: 'Хабарландырулар',
		'Work zones': 'Жұмыс аймақтары',
		Stores: 'Stores',
	},
	cartPage: {
		total: 'итого',
		listOfPurchase: 'Саписок покукоп',
		methodOfPayment: 'Способ оплаты',
		byCash: 'Наличными',
		receipt: 'Квитанция',
		receipts: 'Квитанции',
		addBag: 'Добавить покет',
		goToPayment: 'Перейти к оплате',
		addItem: 'Добавить товар',
		payOnCash: 'Оплатить на кассе',
		payByKaspi: 'Оплатить Kaspi',
		payingOnCash: 'Оплата на кассе',
		stopPurchase: 'Завершить покупку',
		thereIsNoItemsToShow: 'Нет позиций для показа',
		showThisNumberToCashierToMakePayment:
			'Покажите этот номер кассиру, что бы совершить оплату',
		yourOrderNumberIs: 'Ваш номер заказа',
	},
	profilePage: {
		enterCorrectInformation: 'Пожалуйста, введите правильные данные!',
		pleaseEnterCorrectNumber: 'Пожалуйста, введите правильный номер!',
		messageOfToSeeYourOrderHistory:
			'Чтобы смотреть историю ваших заказов, нужно зарегистрировать ваше имя и номер телефон!',
	},
}
