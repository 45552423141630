import { boot } from 'quasar/wrappers'
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'
import { useAuthStore } from 'stores/auth.store'
import { useUiStore } from 'stores/ui.store'

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$axios: AxiosInstance
	}
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
// const api = axios.create({ baseURL: process.env.API_URL })
const api = axios.create({ baseURL: process.env.API_URL })

export default boot(({ app }) => {
	// for use inside Vue files (Options API) through this.$axios and this.$api

	app.config.globalProperties.$axios = axios
	// ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
	//       so you won't necessarily have to import axios in each vue file

	app.config.globalProperties.$api = api
	// ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
	//       so you can easily perform requests against your app's API

	const userStore = useAuthStore()
	const uiStore = useUiStore()
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	if (userStore.tokens && userStore.tokens.accessToken !== '') {
		axios.defaults.headers.common.Authorization = `Bearer ${userStore.tokens.accessToken}`
		api.defaults.headers.common.Authorization = `Bearer ${userStore.tokens.accessToken}`
	}

	api.interceptors.request.use(
		async (config) => {
			if (!config.headers)
				config.headers = {
					Authorization: `Bearer ${userStore.tokens.accessToken}`,
					Accept: 'application/json',
				} as AxiosRequestHeaders
			return config
		},
		(error) => {
			void Promise.reject(error)
		},
	)

	// Response interceptor for API calls
	api.interceptors.response.use(
		(response) => {
			if (response.data.error) throw uiStore.showError(response.data.error)
			return response
		},
		async function (error) {
			if (error?.response?.status === 403) {
				// TODO: Get refreshed token
			}
			throw uiStore.showError(error)
		},
	)
})

export { api }
