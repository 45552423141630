import { defineStore } from 'pinia'
import { api } from 'boot/axios'
import {
	ILoginByEmailInput,
	IRegisterByEmailInput,
	ITokens,
	IUserWithTokens,
	UserGroupsEnum,
} from 'src/_shared/auth'
import { IUserEntity } from 'src/_shared/entities'
import { IResponse } from 'src/common/interfaces'

export const useAuthStore = defineStore('authStore', {
	state: () => ({
		user: {} as IUserEntity,
		tokens: {} as ITokens,
	}),
	getters: {},
	actions: {
		isLoggedIn() {
			return Boolean(this.tokens?.accessToken && this.user)
		},

		async registerByEmail({
			email,
		}: Pick<ILoginByEmailInput, 'email'>): Promise<IUserWithTokens> {
			const { data } = await api.post<IResponse<IUserWithTokens>>(
				'/auth/register-by-email',
				{
					email,
					group: UserGroupsEnum.customers,
				} as IRegisterByEmailInput,
			)
			return data.data
		},

		async loginByEmail({
			email,
			password,
		}: ILoginByEmailInput): Promise<IUserWithTokens> {
			const { data } = await api.post<IResponse<IUserWithTokens>>(
				'/auth/login-by-email',
				{
					email,
					password,
				} as ILoginByEmailInput,
			)
			this.$state.user = data.data.user
			this.$state.tokens = data.data.tokens
			return { user: data.data.user, tokens: data.data.tokens }
		},

		async isEmailExists(email: string) {
			const { data } = await api.post<IResponse<boolean>>(
				'/auth/is-email-exists',
				{
					email,
				},
			)
			return data.data
		},

		async validateEmailByCode(code: string): Promise<IUserWithTokens> {
			const { data } = await api.post<IResponse<IUserWithTokens>>(
				'/auth/activate-email/by-code',
				{
					code,
				},
			)
			this.$state.user = data.data.user
			this.$state.tokens = data.data.tokens
			return { user: data.data.user, tokens: data.data.tokens }
		},

		async loginByPhone({
			phone,
			method = 'CALL',
		}: {
			phone: string
			method?: 'CALL' | 'SMS'
		}) {
			const { data } = await api.post<IResponse<{ status: 'OK' | 'ERROR' }>>(
				'/auth/login-by-phone',
				{
					phone,
					method,
				},
			)
			return data.data
		},

		async validatePhone({ phone, code }: { phone: string; code: string }) {
			const { data } = await api.post<IResponse<IUserWithTokens>>(
				'/auth/validate-phone',
				{
					phone,
					code,
				},
			)
			this.$state.user = data.data.user
			this.$state.tokens = data.data.tokens
			return { user: data.data.user, tokens: data.data.tokens }
		},

		async saveMyUser(userUpdated: IUserEntity) {
			const { data } = await api.patch<IResponse<IUserEntity>>(
				`/user/${userUpdated.id}`,
				userUpdated,
			)
			this.$state.user = data.data
			return this.$state.user
		},

		async getUserDataByToken(): Promise<IUserWithTokens> {
			const jwtToken = `Bearer ${this.$state.tokens.accessToken}`
			const { data } = await api.get<IResponse<IUserEntity>>('/auth/me', {
				headers: {
					Authorization: jwtToken,
				},
			})
			this.$state.user = data.data
			return { user: data.data, tokens: this.$state.tokens }
		},
	},

	persistedState: {
		persist: true,
	},
})
